<template lang="">
  <Layout>
    <template>
      <PageHeader :title="title" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div
              v-if="short_url"
              class="d-flex justify-content-center align-items-center m-2"
            >
              <p
                style="
                  font-size: 20px;
                  font-weight: bold;
                  margin-right: 15px;
                  margin-bottom: 0;
                "
                id="link-text"
              >
                {{ short_url }}
              </p>
              <b-button
                variant="primary"
                class="btn-sm"
                @click="copyToClipboard"
              >
                Copy Link
              </b-button>
            </div>

            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-12"
              >
                <label style="">Link <span style="color: red">*</span></label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter link"
                  v-model="form.link"
                  :class="{
                    'is-invalid': submitted && $v.form.link.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && !$v.form.link.required"
                  class="invalid-feedback"
                >
                  link is required.
                </div>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="">Domain </label>
                <!-- <b-form-input
                  id="input-1"
                  placeholder="Enter Domain"
                  v-model="form.domain"
                ></b-form-input> -->

                <multiselect
                  id="item"
                  :options="domains"
                  v-model="form.domain"
                  :multiple="false"
                  track-by="address"
                  label="address"
                  placeholder="Type here to search"
                >
                  <span slot="noOptions"> Type here to search </span>
                </multiselect>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <label style="">Custom Url </label>
                <b-form-input
                  id="input-1"
                  placeholder="Enter coustom url"
                  v-model="form.customurl"
                ></b-form-input>
              </b-form-group>

              <div class="col-12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                >
                  <span>Submit</span>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import urlMixin from "../../../mixins/ModuleJs/url-shortner";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  mixins: [MixinRequest, urlMixin],
  data() {
    return {
      title: "URL SHORTNER",
      items: [
        {
          text: "Back",
          href: "/",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  validations: {
    form: {
      link: { required },
    },
  },
};
</script>
