import { urlShortner } from "../../js/path";

export default {
  data() {
    return {
      submitted: false,
      short_url: null,
      domains: [],
      token: localStorage.getItem("urlShortnerToken") ?? null,

      form: {
        link: null,
        domain: null,
        customurl: null,
      },
    };
  },
  methods: {
    copyToClipboard() {
      const textToCopy = document.getElementById("link-text").innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Link copied.",
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    },

    checkWebsiteLinkValidity() {
      const validCharactersRegex = /^[a-zA-Z0-9_.:/-]*$/;

      if (!validCharactersRegex.test(this.form.link)) {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Invalid characters in link",
        });
        return false;
      }
      return true;
    },

    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }

      this.$store.commit("loader/updateStatus", true);
      try {
        let url = urlShortner.generateUrl;
        const formData = new FormData();

        for (var key in this.form) {
          if (key !== "domain" && key !== "link") {
            if (this.form[key] !== null && this.form[key] !== undefined) {
              formData.append(key, this.form[key]);
            }
          }
        }

        formData.append("target", this.form?.link);
        if (this.form.domain !== null && this.form.domain !== undefined) {
          formData.append("domain", this.form.domain?.address);
        }
        formData.append("token", this.token);
        formData.append("showAdvanced", true);

        const data = await this.postRequest(url, formData);

        if (data.status && data?.response?.error) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: data.response ? data.response.error : "Please try again!",
          });
        } else {
          this.short_url = data.response.link;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Short url generated successfully.",
          });
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async login() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = urlShortner.generateUrl;
        const data = await this.getRequest(url);
        if (data.status) {
          this.token = data.response.token;
          localStorage.setItem("urlShortnerToken", this.token);
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async renewToken() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = urlShortner.renewToken;
        const formData = new FormData();
        formData.append("token", this.token);
        const data = await this.postRequest(url, formData);
        if (data.status) {
          this.token = data.response.token;
          localStorage.setItem("urlShortnerToken", this.token);
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async getDomains() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = urlShortner.getDomain;
        const formData = new FormData();
        formData.append("token", this.token);
        const data = await this.postRequest(url, formData);
        if (data.status) {
          this.domains = data.response.domains;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },

  watch: {
    token: {
      handler: function () {
        this.getDomains();
      },
    },
  },

  created() {
    this.login();
  },
};
